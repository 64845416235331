<template>
    <div class="marketplace-container">
        <div class="header">
            <div class="header-title">
                <div class="main text">Marketplace</div>
            </div>
        </div>
        <div class="asset-body mt-10">
            <v-row>
                <v-col lg="3" class="token" v-for="(token, index) in marketplace" :key="index">
                    <v-img :src="token"></v-img>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    name: "AllMarketplace",
    data() {
        return {
            marketplace: [
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F1.svg?alt=media&token=c6a628c5-442f-4878-9bd2-0052257c3254',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F2.svg?alt=media&token=46250dac-3f4c-49b7-b262-4c3335e07270',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F3.svg?alt=media&token=d310d5fc-f261-441d-8fad-a0cd5efc16f9',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F4.svg?alt=media&token=d234824a-c42c-4c80-a206-ad931ae9e2fe',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F5.svg?alt=media&token=ccc0da66-2d81-4655-b1c1-bc6ece8f61cd',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F6.svg?alt=media&token=0f4dc8bb-a0a9-43cb-8773-7246c837d7b1',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F7.svg?alt=media&token=f4e326bc-3f8c-4c3d-8961-383c6eb8d393',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F8.svg?alt=media&token=434b5697-8520-47de-8ad4-b9051b44fde4',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F9.svg?alt=media&token=5cd5b2c1-0e59-4f2c-b419-184db077b7ac',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F10.svg?alt=media&token=5094b787-61c1-4ea7-9c5d-70e65ed765c5',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F11.svg?alt=media&token=4e1d1bb4-7aa0-4079-91d7-909e322d3bdc',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F12.svg?alt=media&token=d3b02e35-f943-4beb-9e4d-2f55f9049c46',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F13.svg?alt=media&token=199c22db-9796-4d9d-8174-d6438e111e2c',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F14.svg?alt=media&token=71059053-2148-4d2f-b2d6-d3d2583ae8c7',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F15.svg?alt=media&token=56afceab-3e20-4c4e-bd61-1c4e86e90a9d',
                'https://firebasestorage.googleapis.com/v0/b/signax-d7668.appspot.com/o/website%2Fnew-marketplace%2F16.svg?alt=media&token=ef4db713-5311-4d69-af67-0a386e4a183c'
            ],
        }
    },
}
</script>
<style scoped>
.marketplace-container {
    width: 100vw;
    min-height: 100vh;
    /* background-image: url("../assets/market/market_bg.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    margin-bottom: 20px;
    background-image: url("../assets/hue.svg");
}

.header {
    width: 100%;
}

.header-title {
    height: 60px;
    position: relative;
}

.main {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Orbitron", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px !important;
    width: 40%
}

.asset-body {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>